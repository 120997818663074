<template>
	<div class="layout-footer">
		<!--<img src="logo.jpg" style="height: 40px;"/>-->
		<span class="footer-text" style="margin-right: 5px; margin-left: 5px; color: #d46900">
            <a href="#" target="_blank" class="p-mr-3">
                <i class="pi pi-facebook" style="fontSize: 1.5rem"></i>
            </a>
            <a href="#" target="_blank" class="p-mr-3">
                <i class="pi pi-twitter" style="fontSize: 1.5rem"></i>
            </a>
		</span>
		<span class="footer-text" style="right: 5px">
			<a href="#" target="_blank" class="p-mr-3">
			<i class="pi pi-check-circle" style="fontSize: 1.5rem" v-if="state.estatus"></i>
            <i class="pi pi-exclamation-circle" style="fontSize: 1.5rem" v-else></i> 
			</a>
			<a href="#" target="_blank" class="p-mr-3">
			<i class="pi pi-mobile" style="fontSize: 1.5rem" v-if="state.mobile"></i>
			<i class="pi pi-desktop" style="fontSize: 1.5rem" v-else></i>
			</a>
			@2021
		</span>
	</div>
</template>

<script>
	import store from './store';

	export default {
		data() {
			return {
				state: null
			}
		},
		created() {
			this.state = store.state;
		}
	}
</script>

<style scoped>

</style>